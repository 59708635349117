<template>
  <div class="cardBackground">
    <div class="sectionLine" style="margin: 20px 0px" />
    <p class="cardDetailSectionTitle">{{ "route_label" | localize }}</p>
    <router-link
      :to="`/route/${trip?.route?.slug}/${trip?.route?.id}`"
      style="color: #144fa9 !important"
    >
      <p class="cardDetailSectionText">
        {{ trip?.route?.departure?.translations?.name }} -
        {{ trip?.route?.destination?.translations?.name }}
      </p>
    </router-link>

    <v-col cols="12" xl="6" lg="6" md="6" sm="12" class="px-0 py-0">
      <detail-route-form :routeList="trip?.route?.cities" style="margin-top: 24px" />
    </v-col>
    <div class="sectionLine" style="margin: 20px 0px" />
    <p class="cardDetailSectionTitle">{{ "bus_label" | localize }}</p>
    <p class="cardDetailSectionText">
      <v-row no-gutters align="center">
        <img
          src="@/assets/img/iconsSvg/busIcon.svg"
          width="16px"
          height="16px"
          alt="bus"
          style="margin-right: 10px"
        />
        <router-link :to="`/our_fleet?bus_id=${trip?.bus?.id}`">
          <span style="color: #144fa9; margin-right: 10px"
            >{{ trip?.bus?.manufacturer }} {{ trip?.bus?.model }}</span
          >
        </router-link>

        {{ "bus_govermant_number_label" | localize }}:
        {{ trip?.bus?.government_number }}
      </v-row>
    </p>
  </div>
</template>

<script>
import detailRouteForm from "../Forms/detailRouteForm.vue";
export default {
  components: { detailRouteForm },
  props: {
    trip: {
      require: true,
    },
  },
};
</script>

<style scoped>
.cardBackground {
  border-radius: 10px;
  background: #fcfcfc;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-style: normal;
  line-height: normal;
}
.cardDetailSectionTitle {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
}
.cardDetailSectionText {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}
</style>