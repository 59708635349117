<template>
  <v-col class="px-0 py-0" style="color: #1b1b1b; font-family: 'MacPaw Fixel'">
    <h3 class="pageName">{{ "my_ticket_label" | localize }}</h3>
    <v-row
      no-gutters
      align="center"
      class="statusList"
      style="margin-top: 20px"
    >
      <div
        class="statusBox"
        :class="ticketStatus == 'Active' ? 'activeStatusBox' : ''"
        @click="ticketStatus = 'Active'"
      >
        {{ "active_label" | localize }}
      </div>
      <div
        class="statusBox"
        :class="ticketStatus == 'Archive' ? 'activeStatusBox' : ''"
        @click="ticketStatus = 'Archive'"
      >
        {{ "archive_label" | localize }}
      </div>
    </v-row>
    <loader v-if="showLoader" />
    <v-col v-else class="px-0 py-0" style="margin-top: 20px">
      <v-row no-gutters justify="center" v-if="ticketList.length == 0">
        <v-col cols="5" style="text-align: center">
          <img
            src="@/assets/img/myTicketEmptyList.png"
            width="280px"
            height="280px"
            alt="empty list"
          />
          <p style="font-weight: 600; margin-bottom: 4px">
            {{ "empty_ticket_list_title" | localize }}
          </p>
          <span style="color: #4b5262; font-size: 14px; font-weight: 400">{{
            "empty_ticket_list_subtitle" | localize
          }}</span>
          <v-row no-gutters justify="center" style="margin-top: 25px">
            <submit-button
              style="height: 48px; width: 280px"
              @click="$router.push('/')"
              >{{ "search_ticket_btn" | localize }}</submit-button
            >
          </v-row>
        </v-col>
      </v-row>
      <v-col class="px-0 py-0" v-else>
        <v-col
          class="px-0 py-0"
          v-for="(item, key) in ticketList"
          :key="item.id"
        >
          <p class="date">
            {{
              new Date(key).toLocaleDateString("uk-UA", {
                day: "2-digit",
                month: "long",
                weekday: "long",
              })
            }}
          </p>
          <my-ticket-card
            v-for="ticket in item"
            :key="ticket.id"
            :order="ticket"
            :status="ticketStatus"
            @successCancelOrder="(showLoader = true), getTickets(ticketStatus)"
          />
        </v-col>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import bookingTicketService from "../../../../requests/User/bookingTicketService";
import SubmitButton from "../../../UI/Buttons/submitButton.vue";
import Loader from "../../../UI/Loader.vue";
import myTicketCard from "./myTicketCard.vue";
export default {
  components: { myTicketCard, SubmitButton, Loader },
  data: () => ({
    ticketStatus: "Active",
    ticketList: [],
    page: 1,
    showLoader: true,
  }),
  mounted() {
    this.getTickets(this.ticketStatus);
  },
  methods: {
    async getTickets(status) {
      let search =
        status == "Active"
          ? `[status[in]:Payed,PreBooking,NotPayed]`
          : `[status[in]:Archive,Canceled]`;
      await bookingTicketService.getOrders(search, this.page).then((res) => {
        if (res.status == "Success") {
          this.ticketList = this.groupOrderByDate(res.data);
          this.showLoader = false;
        }
      });
    },
    groupOrderByDate(order) {
      const grouped = order.reduce((grouped, order) => {
        const date = order.schedule.departed_at.split("T")[0];
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(order);
        return grouped;
      }, {});
      const sortedGrouped = Object.keys(grouped)
        .sort()
        .reduce((sorted, date) => {
          sorted[date] = grouped[date];
          return sorted;
        }, {});

      return sortedGrouped;
    },
  },
  watch: {
    ticketStatus: {
      handler() {
        this.showLoader = true;
        this.getTickets(this.ticketStatus);
      },
    },
  },
};
</script>

<style scoped>
.statusBox {
  border-radius: 10px;
  background: #fcfcfc;
  color: #1b1b1b;
  box-shadow: 0px 4px 4px 0px rgba(20, 158, 81, 0.1);
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  width: max-content;
  height: max-content;
  font-size: 16px;
  font-weight: 400;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;
}
.statusBox:hover {
  background: #e7edf6;
}
.activeStatusBox {
  background: #144fa9 !important;
  color: #fafafa;
}
.date {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}
</style>