<template>
  <v-col class="px-0 py-0 text">
    <v-row no-gutters>
      <div style="width: 50px">
        <span class="time">{{ routeList[0]?.arrival }}</span>
      </div>
      <div style="width: 20px; margin: 0px 8px">
        <v-col class="px-0 py-0">
          <div
            style="
              width: 18px;
              height: 18px;
              border-radius: 50%;
              border: 2px solid #144fa9;
            "
          ></div>
          <div
            style="
              width: 1px;
              height: 28px;
              background: #989898;
              margin: 2px 0px;
              margin-left: 8px;
            "
          />
        </v-col>
      </div>
      <v-col class="px-0 py-0"  style="text-align: left;">
        <span
          >{{ routeList[0]?.station?.translations?.name }},
          {{ routeList[0]?.station?.translations?.address }}</span
        >
      </v-col>
    </v-row>
    <button
      class="detailBtn"
      v-if="!isDetailRoute"
      @click="isDetailRoute = true"
    >
      <v-icon style="margin-right: 8px">mdi-chevron-up</v-icon>
      <v-icon style="margin-right: 12px">mdi-chevron-down</v-icon>
      {{ "detail_route_label" | localize }}
    </button>
    <v-expand-transition>
      <v-col class="px-0 py-0" v-if="isDetailRoute">
        <v-row
          no-gutters
          v-for="route in routeList.slice(1, routeList.length - 1)"
          :key="route.id"
        >
          <div style="width: 50px">
            <span class="time">{{ route?.arrival }}</span>
          </div>
          <div style="width: 20px; margin: 0px 8px 0px 6px">
            <v-col class="px-0 py-0">
              <img src="@/assets/img/iconsSvg/mapMarker.svg" alt="map marker" />
              <div
                style="
                  width: 1px;
                  height: 28px;
                  background: #989898;
                  margin: 2px 0px;
                  margin-left: 10px;
                "
              />
            </v-col>
          </div>
          <v-col class="px-0 py-0"  style="text-align: left;">
            <span
              >{{ route?.station?.translations?.name }},{{
                route?.station?.translations?.address
              }}</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-expand-transition>
    <v-row no-gutters align="start" style="margin-left: 4px">
      <div style="width: 50px">
        <span class="time" :style="!isDetailRoute ? 'margin-top: 18px' : ''">{{
          routeList[routeList.length - 1]?.arrival
        }}</span>
      </div>
      <div style="width: 20px; margin: 0px 3.5px">
        <v-col class="px-0 py-0">
          <div
            v-if="!isDetailRoute"
            style="
              width: 1px;
              height: 12px;
              background: #989898;
              margin: 2px 0px;
              margin-left: 8px;
            "
          />
          <div
            style="
              width: 18px;
              height: 18px;
              border-radius: 50%;
              border: 2px solid #144fa9;
              padding: 2px;
              display: grid;
              place-items: center;
            "
          >
            <div
              style="
                width: 10px;
                height: 10px;
                background: #144fa9;
                border-radius: 50%;
              "
            ></div>
          </div>
        </v-col>
      </div>
      <v-col
        style="text-align: left"
        class="px-0 py-0"
        :style="!isDetailRoute ? 'margin-top: 17px' : ''"
      >
        <span
          >{{ routeList[routeList.length - 1]?.station?.translations?.name }},
          {{
            routeList[routeList.length - 1]?.station?.translations?.address
          }}</span
        >
      </v-col>
    </v-row>
    <button
      class="detailBtn"
      @click="isDetailRoute = false"
      v-if="isDetailRoute && !isRoute"
    >
      <v-icon style="margin-right: 12px">mdi-chevron-up</v-icon>
      <span> {{ "hide_btn" | localize }} </span>
    </button>
    <v-row no-gutters align="center" style="margin-top: 20px">
      <v-col
        :cols="
          isRoute && !$vuetify.breakpoint.smAndDown
            ? 7
            : isRoute && $vuetify.breakpoint.smAndDown
            ? 12
            : 12
        "
        class="px-0 py-0 d-flex"
      >
        <img
          src="@/assets/img/iconsSvg/warningIcon.svg"
          alt="warning icon"
          style="margin-right: 10px"
        />
        <p class="cardDetailSectionText" style="margin: 0px">
          {{ "time_warning" | localize }}
        </p>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    isDetailRoute: false,
  }),
  props: {
    isRoute: { require: false },
    routeList: {
      require: true,
    },
  },
  mounted() {
    if (this.isRoute) {
      this.isDetailRoute = true;
    }
  },
};
</script>

<style scoped>
.text {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.time {
  font-weight: 500;
}
.detailBtn {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  padding: 4px 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 12px 0px;
}
</style>