<template>
  <div class="boxBackground">
    <img
      src="@/assets/img/iconsSvg/clockIcon.svg"
      alt="clock"
      style="margin-right: 8px"
    />
    <span
      >Бронювання до
      {{
        new Date(booking_at).toLocaleTimeString("uk-UA", {
          hour: "2-digit",
          minute: "2-digit",
        })
      }}</span
    >&nbsp;<span>{{
      new Date(booking_at).toLocaleDateString("uk-UA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    booking_at: {
      require: true,
    },
  },
};
</script>

<style scoped>
.boxBackground {
  border-radius: 10px;
  background: rgba(251, 187, 85, 0.1);
  padding: 8px 16px;
  color: #d98500;
  font-family: "MacPaw Fixel Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>