<template>
  <div class="myTicketCard">
    <v-row no-gutters align="center">
      <v-col class="px-0 py-0">
        <p
          class="myTicketCardTitle"
          :style="status == 'Active' ? '' : 'color:#989898'"
        >
          {{ order?.departure?.translations?.name }}
        </p>
        <p
          class="myTicketCardText"
          :style="status == 'Active' ? '' : 'color:#989898'"
        >
          <span style="font-weight: 600"
            >{{
              new Date(
                addMinutesToDate(
                  order?.schedule?.departed_at,
                  order?.schedule?.route?.cities?.find(
                    (city) => city.station.city_uuid == order.departure.id
                  )?.time_from_start
                )
              ).toLocaleTimeString("uk-UA", {
                hour: "2-digit",
                minute: "2-digit",
              })
            }},</span
          >
          {{
            order?.schedule?.route?.cities?.find(
              (city) => city.station.city_uuid == order.departure.id
            )?.station.translations?.address
          }}
        </p>
      </v-col>
      <v-col class="px-0 py-0">
        <v-row
          no-gutters
          align="center"
          :style="
            $vuetify.breakpoint.smAndDown ? 'margin: 0px 12px 0px 0px' : ''
          "
        >
          <v-col cols="2">
            <div
              style="
                min-width: 17.5px;
                width: 100%;
                max-width: 46px;
                height: 0.5px;
                background-color: #989898;
              "
            ></div>
          </v-col>
          <v-col
            cols="5"
            xl="7"
            lg="7"
            md="7"
            sm="5"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'margin:auto; text-align:center'
                : ''
            "
          >
            <v-row no-gutters align="center" justify="center">
              <span
                class="myTicketCardText"
                style="font-size: 14px"
                :style="status == 'Active' ? '' : 'color:#989898'"
                >{{
                  setDifferentTime(
                    addMinutesToDate(
                      order?.schedule.departed_at,
                      order?.schedule?.route?.cities?.find(
                        (city) => city.station.city_uuid == order.departure.id
                      )?.time_from_start
                    ),
                    addMinutesToDate(
                      order?.schedule.departed_at,
                      order?.schedule?.route?.cities?.find(
                        (city) => city.station.city_uuid == order.destination.id
                      )?.time_from_start
                    )
                  )
                }}</span
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="helpIcon"
                    v-bind="attrs"
                    v-on="on"
                    :style="
                      $vuetify.breakpoint.smAndDown ? '' : 'margin-left: 4px'
                    "
                  ></div>
                </template>
                <span>{{ "time_zone_warning" | localize }}</span>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col cols="2">
            <div
              style="
                min-width: 17.5px;
                width: 100%;
                max-width: 46px;
                height: 0.5px;
                background-color: #989898;
              "
            ></div
          ></v-col>
        </v-row>
      </v-col>
      <v-col class="px-0 py-0">
        <p
          class="myTicketCardTitle"
          :style="status == 'Active' ? '' : 'color:#989898'"
        >
          {{ order?.destination?.translations?.name }}
        </p>
        <p
          class="myTicketCardText"
          :style="status == 'Active' ? '' : 'color:#989898'"
        >
          <span style="font-weight: 600"
            >{{
              new Date(
                addMinutesToDate(
                  order?.schedule?.departed_at,
                  order?.schedule?.route?.cities?.find(
                    (city) => city.station.city_uuid == order.destination.id
                  )?.time_from_start
                )
              ).toLocaleTimeString("uk-UA", {
                hour: "2-digit",
                minute: "2-digit",
              })
            }},</span
          >
          {{
            order?.schedule?.route?.cities?.find(
              (city) => city.station.city_uuid == order.destination.id
            )?.station.translations?.address
          }}
        </p>
      </v-col>
      <v-col
        style="text-align: right"
        class="px-0 py-0"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <submit-button
          @click="downloadTickets(order.id)"
          v-if="status == 'Active' && order.status == 'Payed'"
          style="width: 180px; font-size: 14px"
          >{{ "donwload_ticket_btn" | localize }}</submit-button
        >
        <submit-button
          @click="continueBooking"
          v-if="
            status == 'Active' &&
            order.status == 'PreBooking' &&
            order.tickets.find(
              (ticket) =>
                ticket.first_name == null &&
                ticket.last_name == null &&
                ticket.phone_number == null &&
                ticket.email == null
            )
          "
          style="width: 180px; font-size: 14px"
          >Продовжити купівлю</submit-button
        >
        <submit-button
          @click="payOrder"
          v-else-if="
            status == 'Active' &&
            (order.status == 'NotPayed' || order.status == 'PreBoking') &&
            order.is_payed == 'No' &&
            order.tickets.find(
              (ticket) =>
                ticket.first_name !== null &&
                ticket.last_name !== null &&
                ticket.phone_number !== null &&
                ticket.email !== null
            )
          "
          style="width: 180px; font-size: 14px"
          >Оплатити</submit-button
        >
        <submit-button
          v-else-if="status == 'Archive'"
          style="width: 180px; font-size: 14px"
          >Пошук квитків</submit-button
        >
        <v-btn
          v-if="status == 'Active'"
          class="cancelTripBtn"
          @click="cancelOrder"
          >{{ "cancel_trip_btn" | localize }}</v-btn
        >
        <v-btn v-else class="cancelTripBtn">Видалити</v-btn>
      </v-col>
    </v-row>
    <button
      v-if="$vuetify.breakpoint.smAndDown"
      class="detailBtn"
      style="margin: 20px 0px; width: 100%"
      @click="isDetail = !isDetail"
    >
      {{ "detail_btn" | localize }}
      <v-icon style="margin-left: 10px">{{
        isDetail ? "mdi-chevron-up" : "mdi-chevron-down"
      }}</v-icon>
    </button>
    <v-expand-transition>
      <ticket-search-card-detail
        :trip="order.schedule"
        v-if="isDetail && $vuetify.breakpoint.smAndDown"
      />
    </v-expand-transition>
    <div class="sectionLine" style="margin: 20px 0px" />
    <div
      class="passangerBox"
      v-for="(ticket, index) in order.tickets"
      :key="ticket.id"
      v-show="
        ticket.last_name !== null &&
        ticket.first_name !== null &&
        ticket.phone_number !== null &&
        ticket.email !== null &&
        !$vuetify.breakpoint.smAndDown
      "
    >
      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="px-0 py-0">
        <p style="margin-bottom: 4px">
          {{ ticket?.first_name }} {{ ticket?.last_name }}
        </p>
        <span class="myTicketCardText" style="color: #4b5262">{{
          ticket?.email
        }}</span>
      </v-col>
      <v-col cols="3" class="px-0 py-0">
        <p style="margin-bottom: 0px">
          <span class="myTicketCardText">{{ "type_label" | localize }}:</span
          >&nbsp;{{
            ticket?.discounts?.find(
              (discount) => discount.discount_type == "Social"
            )?.discount?.translations?.title || "Повний"
          }}
        </p>
      </v-col>
      <v-col cols="2" class="px-0 py-0" v-if="!$vuetify.breakpoint.smAndDown">
        <p style="margin-bottom: 0px">
          <span class="myTicketCardText">{{ "seat_label" | localize }}:</span
          >&nbsp;{{
            order.schedule?.reservation_type == "Reserved"
              ? ticket?.seat_number
              : "Вільне"
          }}
        </p>
      </v-col>
      <v-col cols="2" xl="1" lg="1" md="1" sm="2" class="px-0 py-0">
        <v-row no-gutters justify="end">
          <div
            class="iconBackground"
            style="width: 40px; height: 40px; padding: 8px"
            @click="downloadTicket(order.id, index)"
          >
            <div class="downloadTicketIcon" />
          </div>
        </v-row>
      </v-col>
    </div>
    <div
      class="passangerBox"
      v-for="(ticket, index) in order.tickets"
      :key="ticket.id"
      v-show="
        ticket.last_name !== null &&
        ticket.first_name !== null &&
        ticket.phone_number !== null &&
        ticket.email !== null &&
        $vuetify.breakpoint.smAndDown
      "
    >
      <v-col cols="10" xl="3" lg="3" md="3" sm="6" class="px-0 py-0">
        <p style="margin-bottom: 4px">
          {{ ticket?.first_name }} {{ ticket?.last_name }}
        </p>
        <span class="myTicketCardText" style="color: #4b5262">{{
          ticket?.email
        }}</span>
        <p style="margin-bottom: 0px; margin-top: 8px">
          <span class="myTicketCardText">{{ "type_label" | localize }}:</span
          >&nbsp;{{
            ticket?.discounts?.find(
              (discount) => discount.discount_type == "Social"
            )?.discount?.translations?.title || "Повний"
          }}
        </p>
      </v-col>
      <v-col cols="2" xl="1" lg="1" md="1" sm="2" class="px-0 py-0">
        <v-row no-gutters justify="end">
          <div
            class="iconBackground"
            style="width: 40px; height: 40px; padding: 8px"
            @click="downloadTicket(order.id, index)"
          >
            <div class="downloadTicketIcon" />
          </div>
        </v-row>
      </v-col>
    </div>
    <button
      v-if="!$vuetify.breakpoint.smAndDown"
      class="detailBtn"
      style="margin-top: 20px"
      @click="isDetail = !isDetail"
    >
      {{ "detail_btn" | localize }}
      <v-icon style="margin-left: 10px">{{
        isDetail ? "mdi-chevron-up" : "mdi-chevron-down"
      }}</v-icon>
    </button>
    <v-expand-transition>
      <ticket-search-card-detail
        :trip="order.schedule"
        v-if="isDetail && !$vuetify.breakpoint.smAndDown"
      />
    </v-expand-transition>
    <vue-html2pdf
      v-if="order !== null"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      :filename="`Квиток ${order?.departure?.translations?.name} - ${
        order?.destination?.translations?.name
      } ${new Date(order?.schedule?.departed_at).toLocaleDateString('uk-UA', {
        month: 'short',
        day: 'numeric',
      })}`"
      pdf-format="a4"
      :pdf-margin="100"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <pdf-ticket
          :ticket="order"
          :isPdf="isPdf"
          :text="qrText"
          :isFront="true"
          :indexTicket="indexTicket"
        />
      </section>
    </vue-html2pdf>
    <booking-ticket-payment-modal
      v-if="isShowPaymentModal"
      :visible="isShowPaymentModal"
      :detailOrder="order"
      :passangerTypesForUser="passangerTypesForUser"
      :discountAmount="discountAmount"
      :totalPrice="orderSum"
      :liqPayBtn="liqpay_btn"
      @close="isShowPaymentModal = false"
    />
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import diffrentTimeMixin from "../../../../mixins/diffrentTimeMixin";
import submitButton from "../../../UI/Buttons/submitButton.vue";
import ticketSearchCardDetail from "../../../UI/Cards/ticketSearchCardDetail.vue";
import pdfTicket from "../../pdfTicket.vue";
import VueHtml2pdf from "vue-html2pdf";
import bookingTicketService from "../../../../requests/User/bookingTicketService";
import BookingTicketPaymentModal from "../../bookingTicket/bookingTicketPaymentModal.vue";
export default {
  mixins: [diffrentTimeMixin],
  components: {
    submitButton,
    ticketSearchCardDetail,
    pdfTicket,
    VueHtml2pdf,
    BookingTicketPaymentModal,
  },
  data: () => ({
    isDetail: false,
    isPdf: true,
    qrText: "",
    indexTicket: 0,
    loadingBtn: false,
    isShowPaymentModal: false,
    passangerTypesForUser: [],
    orderSum: 0,
    discountAmount: 0,
    liqpay_btn: null,
  }),
  props: {
    order: {
      require: true,
    },
    status: {
      require: false,
    },
  },
  methods: {
    downloadTickets(id) {
      this.loadingBtn = true;
      this.qrText = `${location.protocol}//${location.host}/order_qr/${id}`;
      setTimeout(async () => {
        for (const [index] of this.order.tickets.entries()) {
          this.indexTicket = index;
          await this.$refs.html2Pdf.generatePdf();
          await new Promise((resolve) => setTimeout(resolve, 500));
          this.downloadPDF = true;
        }
      }, 500);
      setTimeout(() => {
        this.loadingBtn = false;
      }, 500);
    },
    downloadTicket(id, index) {
      this.loadingBtn = true;
      this.qrText = `${location.protocol}//${location.host}/order_qr/${id}`;
      setTimeout(async () => {
        this.indexTicket = index;
        await this.$refs.html2Pdf.generatePdf();
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.downloadPDF = true;
      }, 500);
      setTimeout(() => {
        this.loadingBtn = false;
      }, 500);
    },
    async cancelOrder() {
      await bookingTicketService.cancelOrder(this.order.id).then((res) => {
        if (res.status == "Success") {
          this.$emit("successCancelOrder");
        }
      });
    },
    continueBooking() {
      let trip = {
        departureCity: this.order?.departure?.id,
        arrivalCity: this.order.destination?.id,
        date: this.order.created_at.slice(0, 10),
        qty: this.order.seats_count,
        trip_id: this.order?.schedule?.id,
      };
      sessionStorage.setItem("recent_trip_search", JSON.stringify(trip));
      this.$router.push(`/passanger`);
      sessionStorage.setItem("order_uuid", this.order.id);
      sessionStorage.setItem("trip_id", this.order.schedule_id);
      setTimeout(() => {
        this.$router.push(`/passanger`);
        this.$emit("close");
      }, 500);
    },
    async payOrder() {
      this.groupPassanger();
      this.isShowPaymentModal = true;
      await bookingTicketService.getPayBtns(this.order.id).then((res) => {
        if (res.status == "Success") {
          this.liqpay_btn = res.data.liqpay.replace(
            'src="//static.liqpay.ua/buttons/p1ru.radius.png"',
            'src="https://i.ibb.co/SP1cZmj/Group-82.png"'
          );
        }
      });
    },
    groupPassanger() {
      let group = [];
      this.order.tickets.forEach((passanger) => {
        group.push({
          ...passanger,
          title: "Повний",
        });
      });
      this.passangerTypesForUser = this.groupByPrice(group);
      let initialValue = 0;
      this.orderSum = group.reduce(
        (accumulator, item) => accumulator + item.price,
        initialValue
      );
      if (this.order.promocode && this.order.promocode.amount) {
        this.discountAmount =
          this.orderSum * (this.order.promocode.amount / 100);
        this.orderSum = this.orderSum - this.discountAmount;
      }
      if (this.order.expense_bonus && this.order.expense_bonus.amount) {
        this.discountAmount += this.order.expense_bonus.amount;
        this.orderSum = this.orderSum - this.order.expense_bonus.amount;
      }
    },
    groupByPrice(items) {
      const groupedItems = {};

      items.forEach((item) => {
        const price = item.price;
        const title = item.title;

        // Перевіряємо, чи ключ з цією ціною вже існує
        if (!groupedItems[price]) {
          groupedItems[price] = {};
        }

        // Перевіряємо, чи ключ з цим заголовком вже існує
        if (!groupedItems[price][title]) {
          groupedItems[price][title] = [];
        }

        // Додаємо поточний об'єкт до масиву об'єктів з такою ж ціною та заголовком
        groupedItems[price][title].push(item);
      });

      return groupedItems;
    },
  },
  computed: {
    ...mapGetters(["locale"]),
  },
};
</script>
  
  <style scoped>
@media only screen and (max-width: 1023px) {
  .myTicketCard {
    padding: 16px;
    border-radius: 10px;
    background: #fcfcfc;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
  }
  .myTicketCardTitle {
    font-size: 16px;
    font-weight: 600;
    color: #144fa9;
    margin: 0px;
  }
  .myTicketCardText {
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0px;
    margin-top: 4px;
  }
  .myTicketCardSubtitle {
    font-size: 10px;
    font-weight: 400;
    color: #989898;
  }
  .passangerBox {
    display: flex;
    padding: 12px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #f5f5f5;
    font-size: 16px;
    font-weight: 600;
  }
  .detailBtn {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 4px 8px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .cancelTripBtn {
    width: 180px !important;
    height: 35px !important;
    display: grid;
    place-items: center;
    border-radius: 10px !important;
    background: rgba(223, 58, 58, 0.1) !important;
    color: #df3a3a !important;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
    margin-top: 12px;
  }
}
@media only screen and (min-width: 1024px) {
  .myTicketCard {
    padding: 16px;
    border-radius: 10px;
    background: #fcfcfc;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .myTicketCardTitle {
    font-size: 20px;
    font-weight: 700;
    color: #144fa9;
    margin: 0px;
  }
  .myTicketCardText {
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0px;
    margin-top: 4px;
  }
  .myTicketCardSubtitle {
    font-size: 14px;
    font-weight: 400;
    color: #989898;
  }
  .passangerBox {
    display: flex;
    padding: 12px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #f5f5f5;
    font-size: 16px;
    font-weight: 600;
  }
  .detailBtn {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 4px 8px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .cancelTripBtn {
    width: 180px !important;
    height: 35px !important;
    display: grid;
    place-items: center;
    border-radius: 10px !important;
    background: rgba(223, 58, 58, 0.1) !important;
    color: #df3a3a !important;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
    margin-top: 12px;
  }
}
</style>